<template>
  <div v-if="questions.length > 0">
    <div
      v-for="(question, i) in questions"
      :key="question.id"
      v-bind:class="getColorClass(i)"
      class="history__line"
    >
      <div class="line__info">
        <span>{{ question.question }}</span>

        <span v-if="question.startedAt" class="line-info__date">
          {{
            $t('general-assembly.created', {
              date: $d(Date.parse(question.startedAt), 'time'),
            })
          }}
        </span>

        <span v-if="question.closedAt" class="line-info__date">
          {{
            $t('general-assembly.closed', {
              date: $d(Date.parse(question.closedAt), 'time'),
            })
          }}
        </span>
      </div>

      <div v-if="isNotStarted(question)" class="line__results">
        <v-btn
          class="result"
          color="primary"
          @click="startQuestion(question)"
          :disabled="!canStartQuestion"
          data-test-id="start-btn"
        >
          {{ $t('general-assembly.buttons.start-vote') }}
        </v-btn>
      </div>
      <div v-if="question.startedAt || question.closedAt" class="line__results">
        <v-btn class="result" color="primary" outlined>
          {{
            $t('general-assembly.buttons.approve-count', {
              count: countApproved(question),
            })
          }}
        </v-btn>

        <v-btn class="result" color="error" outlined>
          {{
            $t('general-assembly.buttons.reject-count', {
              count: countRejected(question),
            })
          }}
        </v-btn>

        <v-btn class="result" color="gray" outlined>
          {{
            $t('general-assembly.buttons.refrain-count', {
              count: countRefrained(question),
            })
          }}
        </v-btn>
      </div>
    </div>
  </div>
  <div v-else class="history__line line__info">
    {{ $t('general-assembly.no-votes') }}
  </div>
</template>

<script>
import LayoutUtil from '@/helpers/layout/layout.helper';
import { AnswerType } from '@/models';

export default {
  name: 'AssemblyQuestions',
  props: {
    questions: {
      type: Array,
      required: true,
    },
    hasOngoingQuestion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasQuestions() {
      return this.questions?.length > 0;
    },
    canStartQuestion() {
      return !this.hasOngoingQuestion;
    },
  },
  methods: {
    getColorClass(index) {
      return { line__gray: LayoutUtil.oneInTwo(index) };
    },
    startQuestion(question) {
      this.$emit('start-question', question);
    },
    findResultCount(question, answerType) {
      const result = question.result?.results.find((result) => result.answer === answerType);

      return result?.count || 0;
    },
    countApproved(question) {
      return this.findResultCount(question, AnswerType.APPROVE);
    },
    countRejected(question) {
      return this.findResultCount(question, AnswerType.REJECT);
    },
    countRefrained(question) {
      return this.findResultCount(question, AnswerType.REFRAIN);
    },
    isNotStarted(question) {
      return !question.startedAt && !question.closedAt;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

.history {
  &__line {
    width: 100%;

    display: flex;
    flex-direction: row;
  }
}

.line {
  &__info {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 1rem;
  }

  &__results {
    flex: 2;
    display: flex;
    justify-content: space-evenly;

    margin: 1rem;
  }

  &__gray {
    background: #e1e2e2;
  }
}

.line-info {
  &__date {
    font-size: $small-font-size;
  }
}

.result {
  margin: 0.5rem 0;

  width: 12rem;
}
</style>
