<template>
  <v-sheet v-if="event && generalAssembly">
    <v-container class="px-0 py-0" id="general-assembly-admin" fluid>
      <v-sheet class="general-assembly-admin">
        <div class="first-line">
          <video-switcher
            class="video"
            :user="loggedInUser"
            :video="generalAssembly"
            disable-sound
            show-zoom-info
          />

          <v-sheet dark class="chat__wrapper">
            <chat
              class="chat"
              v-if="hasChatRoom"
              :messages="messages"
              :user="loggedInUser"
              :canModerate="true"
              @load-more="loadMessages"
              @moderate-message="moderateMessage"
              @send-message="sendMessage"
            />
          </v-sheet>
        </div>

        <v-sheet light class="question">
          <div v-if="!question || questionHasCloseDate" class="question__wrapper">
            <span class="question__label">{{ $t('general-assembly.new-question') }}</span>

            <v-textarea
              v-model="questionForm.question"
              rows="1"
              class="question__input"
              data-test-id="question"
            />

            <v-btn
              class="question__button"
              color="primary"
              @click="addQuestion()"
              data-test-id="add-question-btn"
            >
              {{ $t('general-assembly.buttons.save-question') }}
            </v-btn>
          </div>

          <div v-else class="question__wrapper">
            <span class="question__text">{{ question.question }}</span>

            <v-btn
              class="question__button"
              color="primary"
              @click="closeQuestion()"
              data-test-id="close-question-btn"
            >
              {{ $t('general-assembly.buttons.close-vote') }}
            </v-btn>

            <div class="question__started">
              {{
                $t('general-assembly.created', {
                  date: $d(Date.parse(question.created), 'long'),
                })
              }}
            </div>
          </div>
        </v-sheet>

        <v-sheet class="history__title" dark>
          <h3>{{ $t('general-assembly.waiting-votes') }}</h3>
        </v-sheet>

        <v-sheet
          v-if="!questionResultsAreLoading && filteredWaitingQuestionsResults"
          class="history__content"
          light
        >
          <assembly-questions
            :questions="filteredWaitingQuestionsResults"
            :hasOngoingQuestion="hasOngoingQuestion"
            @start-question="startQuestion"
          />
        </v-sheet>

        <v-sheet class="history__title" dark>
          <h3>{{ $t('general-assembly.vote-history') }}</h3>
        </v-sheet>

        <v-sheet
          v-if="!questionResultsAreLoading && filteredQuestionsResults"
          class="history__content"
          light
        >
          <assembly-questions :questions="filteredQuestionsResults" />
        </v-sheet>
      </v-sheet>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Chat from '@/components/chat/Chat.vue';
import VideoSwitcher from '@/components/video-switcher/VideoSwitcher.vue';
import AssemblyQuestions from '@/components/assembly/assembly-questions/AssemblyQuestions.vue';

import DataUtil from '@/helpers/data/data.helper';

import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APP_ASSEMBLY_MODULE,
  ADD_QUESTION,
  ASSEMBLY_CHAT_LOAD_MESSAGES,
  ASSEMBLY_SEND_CHATS_MESSAGE,
  CLOSE_QUESTION,
  CONNECT_TO_ASSEMBLY_ADMIN_SOCKET,
  DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET,
  GET_QUESTION,
  GET_QUESTIONS,
  GET_QUESTIONS_RESULTS,
  MODERATE_ASSEMBLY_CHAT_MESSAGE,
  START_QUESTION,
} from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { GET_ASSEMBLY_BY_ID } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';

export default {
  name: 'GeneralAssemblyAdmin',
  components: {
    AssemblyQuestions,
    Chat,
    VideoSwitcher,
  },
  data: () => ({
    questionForm: {
      question: '',
    },
  }),
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_ASSEMBLY_MODULE, [
      'generalAssembly',
      'questions',
      'question',
      'questionResults',
      'questionResultsAreLoading',
      'messages',
      'assemblyMessagesAreLoading',
    ]),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    filteredQuestionsResults() {
      return DataUtil.sortByStringDesc(
        this.questionResults.filter((questionResult) => questionResult.closedAt),
        'created',
      );
    },
    filteredWaitingQuestionsResults() {
      return DataUtil.sortByStringDesc(
        this.questions.filter((question) => !question.startedAt && !question.closedAt),
        'created',
      );
    },
    hasChatRoom() {
      return !!this.generalAssembly.chatId;
    },
    questionHasCloseDate() {
      return !!this.question?.closedAt;
    },
    hasOngoingQuestion() {
      return !!this.question;
    },
  },
  methods: {
    ...mapActions(APP_ASSEMBLY_MODULE, [
      GET_ASSEMBLY_BY_ID,
      GET_QUESTIONS,
      ADD_QUESTION,
      CLOSE_QUESTION,
      START_QUESTION,
      GET_QUESTION,
      GET_QUESTIONS_RESULTS,
      CONNECT_TO_ASSEMBLY_ADMIN_SOCKET,
      DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET,
      ASSEMBLY_CHAT_LOAD_MESSAGES,
      ASSEMBLY_SEND_CHATS_MESSAGE,
      MODERATE_ASSEMBLY_CHAT_MESSAGE,
    ]),
    sendMessage(message) {
      this[ASSEMBLY_SEND_CHATS_MESSAGE]({
        eventId: this.generalAssembly.eventId,
        assemblyId: this.generalAssembly.id,
        chatId: this.generalAssembly.chatId,
        message,
      });
    },
    async initialize() {
      const { eventId, assemblyId } = this.$route.params;
      await this[GET_ASSEMBLY_BY_ID]({
        eventId,
        assemblyId,
      });
      this[CONNECT_TO_ASSEMBLY_ADMIN_SOCKET]({ assemblyId });
      this[GET_QUESTIONS_RESULTS]({
        eventId,
        assemblyId,
      });
      this[GET_QUESTIONS]({
        eventId,
        assemblyId,
      });
    },
    async addQuestion() {
      this[ADD_QUESTION]({
        ...this.$route.params,
        questionForm: this.questionForm,
      });
      this.questionForm.question = '';
    },
    async closeQuestion() {
      this[CLOSE_QUESTION]({
        ...this.$route.params,
        questionId: this.question.id,
      });
    },
    async startQuestion(question) {
      this[START_QUESTION]({
        ...this.$route.params,
        questionId: question.id,
      });
    },
    moderateMessage(message) {
      this[MODERATE_ASSEMBLY_CHAT_MESSAGE]({
        eventId: this.generalAssembly.eventId,
        assemblyId: this.generalAssembly.id,
        message,
      });
    },
    async loadMessages() {
      if (!this.assemblyMessagesAreLoading) {
        await this[ASSEMBLY_CHAT_LOAD_MESSAGES](this.$route.params);
      }
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this[DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET](this.$route.params.assemblyId);
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

#general-assembly-admin {
  min-height: calc(100vh - 6rem);
}

.general-assembly-admin {
  display: flex;
  flex-direction: column;
}

.video {
  padding: 0 1rem;
  width: 100%;

  ::v-deep #zoom {
    width: 100%;
    height: 100%;
  }
}

.chat {
  width: 100%;
}

.question {
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    width: 90%;
    margin: auto;
  }

  &__label {
    width: 100%;
  }

  &__input {
    width: 80%;
  }

  &__button {
    margin: 1rem;
    width: 20%;
  }

  &__text {
    width: 80%;
  }

  &__started {
    font-size: $small-font-size;
  }
}

.history {
  &__title {
    margin-top: 1rem;
    height: 3rem;
    width: 100%;

    display: flex;
    align-items: center;
  }

  &__content {
    width: 100%;

    display: flex;
    flex-direction: column;
  }
}

@include breakpoint(small) {
  .general-assembly-admin {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .first-line {
    display: flex;
    flex-direction: row;
    height: 20rem;
    width: 100%;
    align-items: center;
  }

  .video {
    width: 35.5rem !important;
    height: 20rem !important;
  }

  .chat {
    height: 20rem;

    &__wrapper {
      height: 20rem;
      width: calc(100% - 35.5rem);
    }
  }

  .question {
    &__label {
      width: 25%;
    }

    &__input {
      width: 50%;
    }

    &__button {
      margin: 1rem;
      width: 20%;
    }

    &__text {
      width: 70%;
    }
  }
}

@include breakpoint(medium) {
  .line {
    &__results {
      flex-direction: row;
      align-items: center;
    }
  }

  .result {
    margin: 0 1rem;
  }
}
</style>
