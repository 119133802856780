<template>
  <div v-if="description" id="agenda">
    <h2 class="title">{{ $t('schedule.title') }}</h2>
    <div class="description" v-html="description"></div>
  </div>
</template>

<script>
export default {
  name: 'Agenda',
  props: ['description'],
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.title {
  border-bottom: 1px solid var(--v-primary-base);
  padding-left: 1rem;
  width: 80%;

  margin-bottom: 1rem;
}

.description {
  white-space: pre-wrap;
  padding: 0.5rem;

  font-size: $default-font-size;
}

@include breakpoint(small) {
  .description {
    max-height: 15rem;
    overflow-y: scroll;
  }
}
</style>
